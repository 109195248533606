$(function(){
  var $sb = $('nav.sidebar');
  var showClass = 'sidebar-show';

  function hideSidebar() {
    $sb.removeClass(showClass)
    $(document).off('click', closeIfClickOutside);
  }
  function closeIfClickOutside(e) {
    if (!$(e.target).closest('nav.sidebar').length) {
      e.preventDefault();
      hideSidebar();
    }
  }
  function showSidebar() {
    $sb.addClass(showClass)
    $(document).on('click', closeIfClickOutside);
  }
  $('.sidebar-toggle').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    if (!$sb.hasClass(showClass)) {
      showSidebar();
    } else {
      hideSidebar();
    }
  })

  // expand/contract ancestor links
  var $sa = $('.sidebar-title.ancestor');
  var saExpClass = 'sa-expanded';
  $sa.on('click', function() {
    if ($sa.hasClass(saExpClass)) {
      $sa.next('.ancestor').hide();
      $sa.removeClass(saExpClass);
    } else {
      $sa.next('.ancestor').show();
      $sa.addClass(saExpClass);
    }
  })
});