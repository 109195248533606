$(function(){
  var $fm = $("#flash_messages"),
      fm_show = 'flash_show';
  if ($fm.length > 0) {
    $fm.show();
    setTimeout(function() {
      $fm.addClass(fm_show);
      setTimeout(function() {
        $fm.removeClass(fm_show);
        setTimeout(function() {
          $fm.hide();
        }, 1500);
      }, 2750);
    }, 0);  
  }
});