$(function(){
  var $eventRoot = $('#upcoming-events');
  var $eventItem = $eventRoot.find('.event-item').first();
  var eventFail = function() {
    $eventRoot.find('.event-fail').show();
  };
  var eventTemplate = function(obj) {
    var $ev = $eventItem.clone().removeAttr('style');
    var $details = $ev.find('.event-details');
    $ev.find('.event-day').text(obj.day);
    $ev.find('.event-month').text(obj.month);
    $details.find('h3').html('<a href="'+obj.link+'">'+obj.summary+'</a>');
    $details.find('time').text(obj.time);
    $details.find('.event-description').text(obj.description)
    return $ev;
  }
  if ($eventRoot.length) {
    $.getJSON('home/events', { maxResults: 3 })
      .fail(eventFail)
      .done(function(data){
        var $eventList = $eventRoot.find('ol');
        if (data) {
          len = data.length;
          for (var i = 0; i < len; i++) {
            eventTemplate(data[i]).appendTo($eventList);
          }
        }
        else {
          eventFail();
        }
      })
      .always(function(){$eventRoot.find('.event-loading').hide();});
  }
});