jQuery(document).ready(function($) {
  var mm = $("#main-menu");
  var mmt = $("#main-menu-toggle");
  var sm = $("ul.submenu");
  // insert a back button for every submenu
  sm.prepend("<li class=\"menu-back\"><a href=\"#\">back</a></li>");

  function closeIfOpen() {
    if (mm.hasClass("menu-show")) {
      mmt.removeClass("menu-show");
      mm.removeClass("menu-show");
      mm.css( {
        'left':'',
        'right':''
      });
      sm.removeAttr('style');
      $(document).off("click", closeIfClickOutside);
      return true;
    } else {
      return false;
    }
  }
  function closeIfClickOutside(event) {
    if (!$(event.target).closest("header nav").length) {
      event.preventDefault();
      closeIfOpen();
    }
  }

  mmt.on('click',function() {
    if (!closeIfOpen()) {
      mm.addClass("menu-show");
      mmt.addClass("menu-show");
      $(document).on("click", closeIfClickOutside);
    }
  });
  sm.parent().children("a").click(function(event) {
    if ($(window).width() > 767) {
      return;
    }
    var child = $(this).parent().children(".submenu");
    if (child.length) {
      event.preventDefault();
      $("#main-menu").css( {
        'left':'-100%',
        'right':'100%'
      });
      child.css("left","0");
    }
  })
  $("li.menu-back a").click(function(event) {
    event.preventDefault();
    $(this).parent().parent().css("left","");
    mm.css( {
        'left':'',
        'right':''
      });
  })
  $(window).resize(function() {
    if ($(window).width() > 767) {
      closeIfOpen();
    }
  })
});
